import React, { useState } from 'react';
import {
  MdWeb, MdOutlineMailOutline, MdOutlineFastfood, MdSmartphone, MdOutlineStorefront,
  MdCloudQueue, MdCode,
} from 'react-icons/md';
import { useInViewEffect } from 'react-hook-inview';
import { Player } from '@lottiefiles/react-lottie-player';
import colors from '../utils/colors';
import 'animate.css';
import animation from '../assets/images/services/animation.json';
import background from '../assets/images/services/background.svg';
import ServicesCard from '../components/services/ServicesCard';
import './Services.css';

function Services() {
  const [firstInView, setFirstInView] = useState(false);

  const ref = useInViewEffect(
    ([entry]) => {
      if (entry.isIntersecting) {
        if (!firstInView) setFirstInView(true);
      }
    },
    { threshold: 0.5 },
  );

  return (
    <div id="services" className="services" style={{ backgroundImage: `url(${background})` }}>
      <div className="container">
        <div>
          <div className="title-area">
            <h1 className={`animate__animated ${firstInView ? 'animate__fadeInDown' : 'd-none'}`} style={{ color: colors.primary.normal }}>Servicios</h1>
          </div>
          <div className="area-text">
            <div className="row">
              <div className="d-none d-sm-none d-md-none d-md-block d-lg-block d-xlg-block col-lg-5 col-xl-5">
                <div className="text-image" style={{ marginTop: '-80px' }}>
                  <Player
                    autoplay
                    loop
                    src={animation}
                  />
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-7 col-xl-7 h-100">
                <div ref={ref} className="text-text">
                  <span
                    className={`animate__animated ${firstInView ? 'animate__fadeInUp' : 'd-none'}`}
                  >
                    En aodev contamos con soluciones para todo tipo de negocios y profesionistas.
                  </span>
                  <span
                    className={`animate__animated ${firstInView ? 'animate__fadeInUp' : 'd-none'}`}
                  >
                    ¿Tiene una empresa y necesita darla a conocer?
                    ¿Necesita un correo profesional?
                    ¿Es dueño de un restaurante?
                    ¿Tiene una idea de negocio? ¿Desea expandirse con una tienda en línea?
                  </span>
                  <span
                    className={`animate__animated ${firstInView ? 'animate__fadeInUp' : 'd-none'}`}
                  >
                    Para todo eso y más le ofrecemos las mejores soluciones.
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex flex-row flex-wrap justify-content-center mb-3">
            <ServicesCard
              name="Correo empresarial"
              icon={(
                <MdOutlineMailOutline style={{
                  width: '70%', height: '70%', color: colors.primary.dark, zIndex: '1000',
                }}
                />
              )}
              description="Obtenga un correo empresarial personalizado con dominio @suempresa."
              location="/email"
            />
            <ServicesCard
              name="Páginas web"
              icon={(
                <MdWeb style={{
                  width: '70%', height: '70%', color: colors.primary.dark, zIndex: '1000',
                }}
                />
              )}
              description="Destaque en línea y atraiga nuevos clientes con nuestro servicio de diseño de páginas web."
            />
            <ServicesCard
              name="Menus QR"
              icon={(
                <MdOutlineFastfood style={{
                  width: '65%', height: '65%', marginTop: '-10px', color: colors.primary.dark, zIndex: '1000',
                }}
                />
              )}
              description="Proporcione a sus clientes una experiencia excepcional con nuestro menú QR digital."
              location="/menus"
            />
            <ServicesCard
              name="Apps móviles"
              icon={(
                <MdSmartphone style={{
                  width: '70%', height: '70%', color: colors.primary.dark, zIndex: '1000',
                }}
                />
              )}
              description="Expanda su presencia en línea con una aplicación móvil personalizada para su empresa."
            />
            <ServicesCard
              name="Tienda en línea"
              icon={(
                <MdOutlineStorefront style={{
                  width: '70%', height: '70%', color: colors.primary.dark, zIndex: '1000',
                }}
                />
              )}
              description="Amplíe su presencia en línea y aumente sus ventas con una tienda en línea profesional."
            />
            <ServicesCard
              name="Hosting"
              icon={(
                <MdCloudQueue style={{
                  width: '70%', height: '70%', color: colors.primary.dark, zIndex: '1000',
                }}
                />
              )}
              description="Aloje su sitio web de forma confiable y segura con nuestras soluciones de hosting de alta calidad."
            />
            <ServicesCard
              name="Software"
              icon={(
                <MdCode style={{
                  width: '70%', height: '70%', color: colors.primary.dark, zIndex: '1000',
                }}
                />
              )}
              description="Creamos soluciones de software para abordar sus desafíos empresariales. Su problema, nuestra solución."
            />
          </div>
          <div className="footer-text">
            <span style={{ fontSize: '35px', fontWeight: '600' }}>¿Aún no está seguro de qué necesita?</span>
            <span style={{ fontSize: '20px' }}>Programe una llamada y con gusto le guiaremos hacia la mejor opción.</span>
            <a className="contacto" href="#contacto" style={{ textDecoration: 'none' }}>Contactar</a>
          </div>
        </div>

      </div>
    </div>
  );
}

export default Services;
