import React, { useState } from 'react';
import { useInViewEffect } from 'react-hook-inview';
import 'animate.css';
import { Form, Input, InputNumber } from 'antd';
import contact from '../assets/images/contact/contact.svg';
import './Contact.css';

function Contact() {
  const [firstInView, setFirstInView] = useState(false);
  const [form] = Form.useForm();

  const ref = useInViewEffect(
    ([entry]) => {
      if (entry.isIntersecting) {
        if (!firstInView) setFirstInView(true);
      }
    },
    { threshold: 0.5 },
  );
  const handleFinish = () => {
    const body = `${form.getFieldValue('descripcion')}${form.getFieldValue('telefono') ? `\n Teléfono de contacto: ${form.getFieldValue('telefono')}` : ''} `;
    window.location = `mailto:contacto@aodev.com.mx?Subject=Deseo%20que%20me%20contacten.&body= ${encodeURIComponent(body)}`;
  };

  return (
    <div id="contact" className="contact border">
      <div className="twos">
        <div className="image-areac">
          <img style={{ width: '80%' }} alt="Personas trabajando" src={contact} />
        </div>
        <div ref={ref} className="text-areac">
          <h1>Contáctenos</h1>
          <Form
            id="formContact"
            onFinish={handleFinish}
            layout="vertical"
            form={form}
            name="forms"
          >
            <Form.Item
              name="nombre"
              label="Nombre"
              rules={[{ required: true, message: 'Por favor ingrese un nombre' }]}
            >
              <Input
                placeholder="Pedro López Martínez"
              />
            </Form.Item>
            <Form.Item
              name="telefono"
              label="Teléfono de contacto"
            >
              <InputNumber
                maxLength={10}
                minLength={10}
                style={{ width: '100%' }}
                placeholder="6181807434"
              />
            </Form.Item>
            <Form.Item
              name="descripcion"
              label="Descripción"
              rules={[{ required: true, message: 'Por favor describa el motivo de su contacto' }]}
            >
              <Input.TextArea
                autoSize={{ minRows: 10, maxRows: 10 }}
              />
            </Form.Item>
            <div className="d-flex flex-row justify-content-center">
              <button type="submit" className="btn-contacto">Enviar</button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default Contact;
