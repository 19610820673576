import React, { useState } from 'react';
import { useInViewEffect } from 'react-hook-inview';
import colors from '../utils/colors';
import 'animate.css';
import background from '../assets/images/aboutus/background.svg';
import team from '../assets/images/aboutus/team.svg';
import './AboutUs.css';

function AboutUs() {
  const [firstInView, setFirstInView] = useState(false);

  const ref = useInViewEffect(
    ([entry]) => {
      if (entry.isIntersecting) {
        if (!firstInView) setFirstInView(true);
      }
    },
    { threshold: 0.5 },
  );

  return (
    <div id="aboutus" className="about-us" style={{ backgroundImage: `url(${background})` }}>
      <div className="container">
        <div ref={ref} className="text-area">
          <h1
            className={`animate__animated ${firstInView ? 'animate__fadeInDown' : 'd-none'}`}
            style={{ color: colors.primary.normal }}
          >
            Sobre nuestra agencia
          </h1>
          <div>
            <span className={`animate__animated ${firstInView ? 'animate__fadeInUp' : 'd-none'}`}>
              Somos una agencia de desarrollo de software ubicada en Durango, México.
              Nuestra principal meta es ayudar a los negocios y profesionistas a
              cumplir sus objetivos. Utilizamos nuestros conocimientos para orientar
              a nuestros clientes hacia el mejor camino a seguir.
            </span>
          </div>
        </div>
        <div className="image-area">
          <img style={{ maxWidth: '95%' }} alt="Personas trabajando" src={team} />
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
