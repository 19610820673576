import React from 'react';
import './MenusCard.css';

function MenusCard({ price, title, description }) {
  return (
    <div className="menus-card-container">
      <div className="menus-card-container-zig" />
      <div className="menus-card-top">
        <div style={{ height: '60px' }}>
          <span>{`$${price}`}</span>
        </div>
        <div>
          <p>{description}</p>
        </div>
      </div>
      <div className="menus-card-bottom">
        <span>{title}</span>
      </div>
    </div>
  );
}

export default MenusCard;
