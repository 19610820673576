import React from 'react';
import { Layout } from 'antd';
import MyHeader from '../layouts/MyHeader';
import MyFooter from '../layouts/MyFooter';
import MyBody from '../layouts/MyBody';

const { Header, Content, Footer } = Layout;

function Home() {
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Layout>
        <Header className="p-0 m-0" style={{ zIndex: '999' }}>
          <MyHeader />
        </Header>
        <Content>
          <MyBody />
        </Content>
        <Footer className="p-0 m-0">
          <MyFooter />
        </Footer>
      </Layout>
    </Layout>
  );
}
export default Home;
