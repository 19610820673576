/* eslint-disable import/no-unresolved */

import React, { useEffect } from 'react';
import './Menus.css';
import { Card, Image, Typography } from 'antd';
import { useLocation } from 'react-router-dom';
import { Player } from '@lottiefiles/react-lottie-player';
import { Fade, Slide, Zoom } from 'react-reveal';
import HeadShake from 'react-reveal/HeadShake';

import { PhoneFilled } from '@ant-design/icons';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
  Navigation, Pagination, EffectCoverflow, Autoplay,
} from 'swiper/modules';
import { Helmet } from 'react-helmet';
import MenusCard from '../components/menus/MenusCard';
import ImageQR from '../assets/images/menus/ImageQR.png';
import ImageQR2 from '../assets/images/menus/ImageQR2.png';
import CochitoQR from '../assets/menu/CochitoQR.png';
import QuintaQR from '../assets/menu/QuintaQR.png';
import TaquitoDgoQR from '../assets/menu/TaquitoDgoQR.png';
import TaquitoMtzQR from '../assets/menu/TaquitoMtzQR.png';
import Contact from './Contact';
import MenuAnimation from '../assets/animations/menu.json';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';

import menu1 from '../assets/menu/menu1.jpg';
import menu2 from '../assets/menu/menu2.jpg';
import menu3 from '../assets/menu/menu3.jpg';
import menu4 from '../assets/menu/menu4.jpg';
import menu5 from '../assets/menu/menu5.jpg';
import menu6 from '../assets/menu/menu6.jpg';
import menu7 from '../assets/menu/menu7.jpg';
import menu8 from '../assets/menu/menu8.jpg';

const images = [
  { image: menu1, description: 'Galería inicial con categorías personalizables, buscador avanzado y listado detallado de productos.' },
  { image: menu2, description: 'Diseño adaptable y elegante. Prueba nuestro menú en modo oscuro.' },
  { image: menu3, description: 'Personalize el menú para reflejar el estilo único de su restaurante.' },
  { image: menu4, description: 'Visualización ampliada de imágenes, permitiendo a los clientes ver cada detalle.' },
  { image: menu5, description: 'Buscador integrado para facilitar la búsqueda de productos.' },
  { image: menu6, description: 'Selección de idioma múltiple para una experiencia culinaria inclusiva y accesible.' },
  { image: menu7, description: 'Indique productos no disponibles para evitar inconvenientes a sus clientes.' },
  { image: menu8, description: 'Sección de favoritos para que los clientes puedan guardar y ordenar sus preferencias con facilidad.' },
];

function Menus() {
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname === '/menus') window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className="container menus-container">
      <Helmet>
        <title>Aodev. Menús Digitales</title>
        <meta name="description" content="Revolucione su negocio gastronómico con nuestro elegante menú digital y menú QR. Presente sus exquisitos platillos con imágenes cautivadoras y descripciones apetitosas en un menú para restaurantes que incluye ofertas especiales que harán que sus clientes vuelvan por más." />
        <meta name="keywords" content="Menú Digital, Menú QR, Menú para restaurantes, Herramientas para restaurantes, Carta Digital" />
      </Helmet>
      <div className="d-flex justify-content-center">
        <div className="d-none d-sm-none d-md-none d-md-block d-lg-block d-xlg-block col-lg-5 col-xl-5">
          <Fade left>
            <Player
              style={{ height: '400px' }}
              autoplay
              loop
              src={MenuAnimation}
            />
          </Fade>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-7 col-xl-7 h-100">
          <div className="d-flex flex-column h-100">
            <Slide left cascade>
              <Typography.Title style={{
                color: '#ED6E56', fontSize: '60px', marginBottom: '30px', marginTop: '15px',
              }}
              >
                Menú Digital
              </Typography.Title>
            </Slide>
            <Typography.Title
              style={{
                fontSize: '25px', lineHeight: 1.5, fontWeight: '500', textAlign: 'justify',
              }}
              level={3}
            >
              ¡Revolucione su negocio
              gastronómico con nuestro elegante menú digital!
            </Typography.Title>
            <Typography.Paragraph
              style={{
                fontSize: '20px', lineHeight: 1.5, fontWeight: '400', textAlign: 'justify',
              }}
              level={3}
            >
              Presente sus exquisitos platillos con imágenes cautivadoras y descripciones
              apetitosas en un menú para restaurantes que incluye ofertas especiales que
              harán que sus clientes vuelvan por más.
            </Typography.Paragraph>
          </div>
        </div>
      </div>
      <div className="d-flex flex-column align-items-center">
        <HeadShake>
          <Typography.Title style={{ color: '#145DA0' }} level={2}>Paquetes</Typography.Title>
        </HeadShake>
        <div className="d-flex flex-row justify-content-center flex-wrap">
          <MenusCard title="Básico" price={250} description="Enlace del tipo sunegocio. aodev.com.mx" />
          <MenusCard title="Medio" price={300} description="Enlace del tipo sunegocio.com" />
          <MenusCard title="Avanzado" price={350} description="Enlace del tipo sunegocio.com. 1 cuenta de correo" />
        </div>
      </div>
      <div className="d-flex flex-column align-items-center">
        <HeadShake>
          <Typography.Title style={{ color: '#145DA0' }} level={2}>Conozca nuestro menú</Typography.Title>
        </HeadShake>
        <Swiper
          effect="coverflow"
          grabCursor
          centeredSlides
          loop
          slidesPerView="auto"
          coverflowEffect={{
            rotate: 0,
            stretch: 0,
            depth: 100,
            modifier: 2.5,
          }}
          scrollbar={{ draggable: true }}
          pagination={{ el: '.swiper-pagination', clickable: true }}
          modules={[Autoplay, EffectCoverflow, Pagination, Navigation]}
          className="swiper-slide-custom"
        >
          {images.map(({ image, description }) => (
            <SwiperSlide style={{ width: '300px' }}>
              {({ isActive }) => (
                <>
                  <Image className={isActive ? '' : 'image-inactive'} style={{ width: '300px' }} src={image} alt="Foto con promoción" />
                  <div
                    style={{
                      position: 'absolute',
                      bottom: '10px',
                      left: '50%',
                      transform: 'translateX(-50%)',
                      color: isActive ? 'white' : 'gray',
                      textAlign: 'center',
                      backgroundColor: isActive ? 'rgba(0, 0, 0, 0.5)' : 'transparent',
                      padding: '5px',
                      borderRadius: '5px',
                      width: '90%',
                    }}
                  >
                    {description}
                  </div>
                </>
              )}

            </SwiperSlide>
          ))}
          <div className="slider-controler">
            <div className="swiper-button-prev slider-arrow">
              <ion-icon name="arrow-back-outline" />
            </div>
            <div className="swiper-button-next slider-arrow">
              <ion-icon name="arrow-forward-outline" />
            </div>
            <div className="swiper-pagination" />
          </div>
        </Swiper>
      </div>
      <div className="d-flex justify-content-center">
        <div className="container-caracteristicas">
          <HeadShake>
            <Typography.Title style={{ color: '#145DA0' }} level={2}>Características generales</Typography.Title>
          </HeadShake>
          <ul className="custom-ul">
            <Zoom top cascade>
              <li>
                <div className="d-flex flex-column">
                  <span className="title-li">Control de productos y disponibilidad.</span>
                  <span className="body-li">
                    Usted mismo puede controlar su menú desde su propia página de internet.
                  </span>
                  <span className="body-li">
                    En cualquier momento puede agregar,
                    editar, pausar o eliminar los productos de su menú.
                  </span>
                </div>
              </li>
              <li>
                <div className="d-flex flex-column">
                  <span className="title-li">Número ilimitado de productos. </span>
                  <span className="body-li">
                    Agregue tantos elementos como sean necesarios. No existe un límite.
                  </span>
                </div>
              </li>
              <li>
                <div className="d-flex flex-column">
                  <span className="title-li">Personalización </span>
                  <span className="body-li">
                    Modifique los colores para ajustarlo a la imagen de su restaurante.
                  </span>
                </div>
              </li>
              <li>
                <div className="d-flex flex-column">
                  <span className="title-li">Idiomas </span>
                  <span className="body-li">
                    Listo para que lo configure en todos los idiomas que sea necesario.
                    Permita que su cliente obtenga el menú en su idioma preferido
                    gracias a la selección automática.
                  </span>
                </div>
              </li>
              <li>
                <div className="d-flex flex-column">
                  <span className="title-li">
                    Cada producto puede contener:
                  </span>
                  <ul>
                    <li className="body-li">
                      Nombre
                    </li>
                    <li className="body-li">
                      1 Foto
                    </li>
                    <li className="body-li">
                      Descripción detallada
                    </li>
                    <li className="body-li">
                      Precio
                    </li>
                  </ul>
                  <span className="body-li">
                    ¿Aún no tiene fotos de sus productos? No se preocupe,
                    puede comenzar sin ellas y cuando las tenga disponibles agregarlas.
                  </span>
                </div>
              </li>
              <li>
                <div className="d-flex flex-column">
                  <span className="title-li"> Favoritos </span>
                  <span className="body-li">
                    Opción para que sus comensales agreguen sus platillos
                    a una sección de favoritos. Así podrán ordenar fácilmente
                    su pedido aunque no estén familiarizados con el platillo.
                  </span>
                  <span className="body-li">
                    Además, con esta sección podrán obtener rapidamente el costo total de
                    sus productos a ordenar.
                  </span>
                </div>
              </li>
              <li>
                <div className="d-flex flex-column">
                  <span className="title-li">Banners con promociones u ofertas.</span>
                  <span className="body-li">
                    Puede agregar imágenes en la parte inicial de su menú.
                  </span>
                  <span className="body-li">
                    ¿Tiene un platillo nuevo? Anúncielo ahí.
                  </span>
                  <span className="body-li">
                    Tiene una promoción. Cargue una nueva imágen.
                  </span>
                </div>
              </li>
              <li>
                <div className="d-flex flex-column">
                  <span className="title-li">Código QR a su gusto.</span>
                  <span className="body-li">
                    Escoja entre nuestros distintos tipos de códigos QR.
                  </span>
                  <span className="body-li">
                    ¿Desea que su logo se muestre dentro del QR? Con nuestro menú puede hacerlo
                  </span>
                </div>
              </li>
              <li>
                <div className="d-flex flex-column">
                  <span className="title-li">Enlace personalizado a su menú.</span>
                  <span className="body-li">
                    Con este enlace podrá compartir su menú de una manera rápida.
                  </span>
                  <span className="body-li">
                    Si quiere compartir su carta a quienes se la piden, en sus redes sociales
                    o hasta en su ubicación en maps, con esta opción será tan
                    sencillo como enviar un enlace.
                    Olvídese de compartir un pdf o una imágen.
                  </span>
                </div>
              </li>
            </Zoom>
          </ul>
        </div>
      </div>
      <div className="prueba-container">
        <HeadShake>
          <Typography.Title style={{ color: '#145DA0' }} level={2}>Pruebe nuestro menú</Typography.Title>
        </HeadShake>
        <div className="d-flex flex-column">
          <span style={{ fontSize: '20px', marginBottom: '10px' }}>
            De click en el siguiente enlace
            <a href="https://menu.aodev.com.mx" style={{ textDecoration: 'none', fontWeight: '500' }}> menu.aodev.com.mx</a>
          </span>
          <span style={{ fontSize: '20px' }}>
            O escanee cualquiera de los siguientes códigos
          </span>
        </div>
        <div className="container-qr">
          <img className="qr" src={ImageQR} alt="Codigo QR" />
          <img className="qr" src={ImageQR2} alt="Codigo QR" />
        </div>
        <div className="d-flex flex-column align-items-center w-100">
          <span style={{ fontSize: '20px', marginBottom: '10px' }}>
            También puede probar el de alguno de nuestros clientes.
          </span>
          <span style={{ fontSize: '20px' }}>
            Vea el menú en acción
          </span>
          <div className="d-flex flex-wrap justify-content-center">
            <Card
              hoverable
              className="mx-2"
              style={{ width: '250px' }}
              onClick={() => { window.open('https://menu.laquintadgo.com'); }}
            >
              <div className="d-flex flex-column align-items-center">
                <img style={{ objectFit: 'cover' }} className="qr-examples" src={QuintaQR} alt="Codigo QR" />
                <a href="https://menu.laquintadgo.com" style={{ textDecoration: 'none', fontWeight: '500', color: 'black' }}> menu.laquintadgo.com</a>
              </div>
            </Card>
            <Card
              hoverable
              style={{ width: '250px' }}
              className="mx-2"
              onClick={() => { window.open('https://menu.cochitosinaloense.com'); }}
            >
              <div className="d-flex flex-column align-items-center">
                <img style={{ objectFit: 'cover' }} className="qr-examples" src={CochitoQR} alt="Codigo QR" />
                <a href="https://menu.cochitosinaloense.com" style={{ textDecoration: 'none', fontWeight: '500', color: 'black' }}> menu.cochitosinaloense.com</a>
              </div>
            </Card>
            <Card
              hoverable
              style={{ width: '250px' }}
              className="mx-2"
              onClick={() => { window.open('https://dgo.taquitogrill.com'); }}
            >
              <div className="d-flex flex-column align-items-center">
                <img style={{ objectFit: 'cover' }} className="qr-examples" src={TaquitoDgoQR} alt="Codigo QR" />
                <a href="https://dgo.taquitogrill.com" style={{ textDecoration: 'none', fontWeight: '500', color: 'black' }}> dgo.taquitogrill.com</a>
              </div>
            </Card>
            <Card
              hoverable
              style={{ width: '250px' }}
              className="mx-2"
              onClick={() => { window.open('https://mzt.taquitogrill.com'); }}
            >
              <div className="d-flex flex-column align-items-center">
                <img style={{ objectFit: 'cover' }} className="qr-examples" src={TaquitoMtzQR} alt="Codigo QR" />
                <a href="https://mzt.taquitogrill.com" style={{ textDecoration: 'none', fontWeight: '500', color: 'black' }}> mzt.taquitogrill.com</a>
              </div>
            </Card>
          </div>
        </div>
      </div>
      <HeadShake>
        <Typography.Title
          style={{
            color: '#145DA0', textAlign: 'center', marginTop: '20px', marginBottom: '10px',
          }}
          level={2}
        >
          ¿Tiene alguna duda o desea contratar el servicio?
        </Typography.Title>
      </HeadShake>
      <div className="d-flex flex-column align-items-center mb-3">
        <a href="tel:6181807434" style={{ fontSize: '50px', textDecoration: 'none' }}>
          <PhoneFilled />
        </a>
        <span>Llámenos</span>
        <a href="tel:6181807434" style={{ textDecoration: 'none', fontWeight: 600, color: 'black' }}>
          6181807434
        </a>
        <span>O llene el siguiente formulario</span>
      </div>
      <Contact />
    </div>
  );
}

export default Menus;
