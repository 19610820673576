import { Player } from '@lottiefiles/react-lottie-player';
import { Button } from 'antd';
import React from 'react';
import { Fade } from 'react-reveal';
import Jump from 'react-reveal/Jump';
import colors from '../utils/colors';
import background from '../assets/images/welcome/background.svg';
import animation from '../assets/images/welcome/animation.json';
import logo from '../assets/images/logo.webp';
import './Welcome.css';

function Welcome() {
  return (
    <div id="welcome" className="welcome" style={{ backgroundImage: `url(${background})` }}>
      <div>
        <div className="container">
          <div className="row h-100">
            <div className="col-sm-12 col-md-12 col-lg-7 col-xl-6">
              <div className="left">
                <div>
                  <Fade left>
                    <img src={logo} alt="logo" />
                  </Fade>
                  <span>¿Tiene una idea? </span>
                  <span>Nosotros la volvemos realidad.</span>
                  <Jump>
                    <Button
                      className="my-5"
                      type="primary"
                      style={{
                        border: '0px', backgroundColor: colors.secondary.normal, height: '5rem', width: '19rem', borderRadius: '50px',
                      }}
                    >
                      <a
                        className="mx-5"
                        href="#contacto"
                        style={{
                          fontSize: '2rem', color: 'white', fontWeight: '400', textDecoration: 'none',
                        }}
                      >
                        Contáctanos
                      </a>
                    </Button>
                  </Jump>
                </div>
              </div>
            </div>
            <div className="d-none d-sm-none d-md-none d-md-block d-lg-block d-xlg-block col-lg-5 col-xl-6" style={{ height: 'calc(100vh - 295px)' }}>
              <div className="right">
                <Player
                  autoplay
                  loop
                  src={animation}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Welcome;
