const colors = {
  primary: {
    normal: '#145DA0',
    light: '#2563EB',
    dark: '#003c8f',

  },
  secondary: {
    normal: '#ED6E56',
    light: '#ff8a50',
    dark: '#c41c00',
  },

};
export default colors;
