import React from 'react';
import { Helmet } from 'react-helmet';
import Router from './router/Router';

function App() {
  return (
    <>
      <Helmet>
        <title>Aodev. Agencia de desarrollo de software.</title>
        <meta name="keywords" content="Desarrollo de software, Menús digitales, Menús QR, Correo empresarial, Páginas Web, Aplicaciones Móviles" />
        <meta name="description" content="En Aodev ofrecemos soluciones integrales para empresas y profesionistas. Ya sea que necesite promocionar su negocio, obtener un correo profesional, expandirse con una tienda en línea o llevar su restaurante al siguiente nivel, tenemos la solución perfecta para usted." />
        <meta httpEquiv="content-language" content="es" />
        <link rel="canonical" href="https://aodev.com.mx" />
      </Helmet>
      <Router />
    </>
  );
}

export default App;
