import React from 'react';
import Services from '../pages/Services';
import Welcome from '../pages/Welcome';
import AboutUs from '../pages/AboutUs';
import Contact from '../pages/Contact';

function MyBody() {
  return (
    <div id="body" className="d-flex flex-column">
      <Welcome />
      <Services />
      <AboutUs />
      <Contact />
    </div>
  );
}
export default MyBody;
