import React, { useEffect } from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import { useLocation } from 'react-router-dom';
import HeadShake from 'react-reveal/HeadShake';
import { Typography } from 'antd';
import { Fade, Slide } from 'react-reveal';
import { Helmet } from 'react-helmet';
import EmailCard from '../components/email/EmailCard';
import colors from '../utils/colors';
import Contact from './Contact';
import './Email.css';

function Email() {
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname === '/email') window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div id="email" className="container email-container" style={{ minHeight: '700px' }}>
      <Helmet title="Aodev. Correo empresarial" />
      <div className="d-flex m-0 py-5" style={{ flexGrow: 1 }}>
        <div className="d-flex flex-column justify-content-center align-items-center w-100 h-100">
          <div className="d-flex flex-row justify-content-center align-items-center">
            <div className="d-none d-sm-none d-md-none d-md-block d-lg-block d-xlg-block col-lg-6 col-xl-6">
              <Fade left>
                <Player
                  style={{ height: '400px' }}
                  autoplay
                  loop
                  src="https://assets3.lottiefiles.com/packages/lf20_se3w0ukg.json"
                />
              </Fade>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 h-100">
              <div className="d-flex flex-column h-100">
                <Slide left cascade>
                  <h1 style={{
                    color: colors.primary.normal, fontWeight: '600', fontSize: '50px', marginTop: '15px',
                  }}
                  >
                    Correo personalizado
                  </h1>
                </Slide>
                <div className="email-container-description">
                  <h2 style={{ textAlign: 'justify' }}>
                    Adquiera su correo electrónico personalizado del tipo
                    <span style={{ color: colors.secondary.normal }}> @suempresa </span>
                  </h2>
                  <span style={{
                    fontSize: '18px', lineHeight: 1.3, fontWeight: '400', textAlign: 'justify',
                  }}
                  >
                    Aumente la confianza de sus clientes brindándoles
                    a los miembros de su empresa una dirección de
                    correo electrónico profesional de su dominio como
                    <b> luis@tunegocio.mx </b>
                    o
                    <b> angel@tunegocio.com</b>
                    .
                  </span>
                  <span style={{ fontSize: '18px', lineHeight: 1.3, fontWeight: '400' }}>
                    También puede crear direcciones de correo
                    electrónico adicionales para diferentes áreas de su empresa como:
                    <b> soporte@tunegocio.mx </b>
                    o
                    <b> ventas@tunegocio.com.</b>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <HeadShake>
            <Typography.Title style={{ color: '#145DA0' }} level={2}>Planes</Typography.Title>
          </HeadShake>
          <div className="email-container-cards">
            <EmailCard title="Inicial" price="$179MXN" description={['2 cuentas', '4gb de almacenamiento', 'Webmail accesible para computadoras teléfonos celulares.', 'Compatible con la aplicación de correo de tu elección']} />
            <EmailCard title="Básico" price="$349MXN" description={['5 cuentas', '8gb de almacenamiento', 'Webmail accesible para computadoras teléfonos celulares.', 'Compatible con la aplicación de correo de tu elección']} />
            <EmailCard title="Avanzado" price="$699MXN" description={['10 cuentas', '20gb de almacenamiento', 'Webmail accesible para computadoras teléfonos celulares.', 'Compatible con la aplicación de correo de tu elección']} />
            <EmailCard title="Premium" price="$1,299MXN" description={['20 cuentas', '50gb de almacenamiento', 'Webmail accesible para computadoras teléfonos celulares.', 'Compatible con la aplicación de correo de tu elección']} />
            <EmailCard title="Personalizado" price="$ libre" description={['Todas las cuentas necesarias', 'Almacenamiento necesario', 'Webmail accesible para computadoras teléfonos celulares.', 'Compatible con la aplicación de correo de tu elección']} />
          </div>
          <div className="d-flex flex-row justify-content-end align-items-end w-100">
            <span style={{ marginRight: '20px' }}>* Costo mensual</span>
          </div>
          <div className="email-container-list">
            <div className="d-flex flex-column align-items-start justify-content-start w-100">
              <HeadShake>
                <Typography.Title
                  style={{
                    color: '#145DA0', textAlign: 'center', marginTop: '20px', marginBottom: '20px',
                  }}
                  level={2}
                >
                  Consideraciones generales:
                </Typography.Title>
              </HeadShake>
              <ul>
                <li style={{ fontSize: '20px' }}>Envío de correos ilimitado.</li>
                <li style={{ fontSize: '20px' }}>Recepción de correos ilimitada (hasta donde el almacenamiento permita).</li>
                <li style={{ fontSize: '20px' }}>Se incluye una copia de seguridad de 7 días en todos los niveles.</li>
                <li style={{ fontSize: '20px' }}>No es necesario tener un dominio comprado, nosotros nos encargamos del trámite.</li>
                <li style={{ fontSize: '20px' }}>El precio no incluye el dominio.</li>
                <li style={{ fontSize: '20px' }}>IVA incluido</li>
              </ul>
            </div>

          </div>
        </div>
      </div>
      <HeadShake>
        <Typography.Title
          style={{
            color: '#145DA0', textAlign: 'center', marginTop: '20px', marginBottom: '20px',
          }}
          level={2}
        >
          ¿Tiene alguna duda o desea contratar el servicio?
        </Typography.Title>
      </HeadShake>
      <Contact />
    </div>
  );
}
export default Email;
