import React from 'react';
import './ServicesCard.css';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';

function ServicesCard({
  name, icon, description, location,
}) {
  const navigate = useNavigate();

  return (
    <div className="card education">
      <div className="overlay" />
      <div className="circle">
        {icon}
      </div>
      <span>{name}</span>

      <p>{description}</p>
      {location
        ? <Button onClick={() => { navigate(location); }} type="link">Ver más</Button>
        : <div style={{ height: '30px' }} />}
    </div>
  );
}

export default ServicesCard;
