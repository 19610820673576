import { MenuOutlined } from '@ant-design/icons/lib/icons';
import { Button } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import {
  Navbar, Nav, Container, NavDropdown,
} from 'react-bootstrap';
import './MyHeader.css';
import {
  MdWeb, MdOutlineMailOutline, MdOutlineFastfood, MdSmartphone,
  MdOutlineStorefront, MdCloudQueue, MdCode,
} from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import colors from '../utils/colors';

function MyHeader() {
  const ref = useRef();
  const [isScrolled, setIsScrolled] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const onScroll = () => {
      if ((window.pageYOffset > 70) !== isScrolled) {
        setIsScrolled(window.pageYOffset > 70);
      }
    };
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, [isScrolled]);

  return (
    <Navbar ref={ref} className={`navbar-custom ${isScrolled ? 'scrolled' : ''}`} collapseOnSelect expand="lg">
      <Container className="px-4">
        <Navbar.Brand className="size_animate" style={{ cursor: 'pointer' }}>
          <a href="/#welcome" className={`${isScrolled ? 'items-scrolled' : 'items-not-scrolled'}`} style={{ fontSize: '2rem', textDecoration: 'none' }}>Aodev</a>
        </Navbar.Brand>
        <Navbar.Toggle className="p-0" aria-controls="responsive-navbar-nav">
          <Button type="text" style={{ borderColor: colors.primary.normal }}>
            <div className="d-flex flex-column justify-content-center">
              <MenuOutlined style={{ color: isScrolled ? colors.primary.normal : 'white' }} />
            </div>
          </Button>
        </Navbar.Toggle>
        <Navbar.Collapse id="responsive-navbar-nav" className={`${isScrolled ? 'nav-scrolled' : 'nav-not-scrolled'}`}>
          <Nav className="me-auto d-flex justify-content-center w-100">
            <Nav.Link className={`size_animate m-2 ${isScrolled ? 'items-scrolled' : 'items-not-scrolled'}`} href="/#welcome">Inicio</Nav.Link>
            <Nav.Link className={`size_animate m-2 ${isScrolled ? 'items-scrolled' : 'items-not-scrolled'}`} href="/menus">Menús Digitales</Nav.Link>
            <NavDropdown title="Servicios" id="basic-nav-dropdown" className={`m-2 ${isScrolled ? 'items-scrolled' : 'items-not-scrolled'} size_animate`}>
              <NavDropdown.Item onClick={() => navigate('/email')}>
                <MdOutlineMailOutline className="m-2" />
                Correo empresarial
              </NavDropdown.Item>
              <NavDropdown.Item href="/#services">
                <MdWeb className="m-2" />
                Páginas web
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => navigate('/menus')}>
                <MdOutlineFastfood className="m-2" />
                Menus QR
              </NavDropdown.Item>
              <NavDropdown.Item href="/#services">
                <MdSmartphone className="m-2" />
                Apps móviles
              </NavDropdown.Item>
              <NavDropdown.Item href="/#services">
                <MdOutlineStorefront className="m-2" />
                Tienda en línea
              </NavDropdown.Item>
              <NavDropdown.Item href="/#services">
                <MdCloudQueue className="m-2" />
                Hosting
              </NavDropdown.Item>
              <NavDropdown.Item href="/#services">
                <MdCode className="m-2" />
                Software
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link className={`size_animate m-2 ${isScrolled ? 'items-scrolled' : 'items-not-scrolled'}`} href="/#aboutus">Nosotros</Nav.Link>
            <Nav.Link href="/#contacto" className={`size_animate m-2 ${isScrolled ? 'items-scrolled' : 'items-not-scrolled'}`}>Contacto</Nav.Link>
          </Nav>

        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
export default MyHeader;
