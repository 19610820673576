import { FacebookFilled, MailFilled, PhoneFilled } from '@ant-design/icons';
import React from 'react';
import moment from 'moment';
import colors from '../utils/colors';

function MyFooter() {
  return (
    <div id="contacto" style={{ backgroundColor: '#FAFAFA' }}>
      <div className="d-flex justify-content-center">
        <h2 className="mt-3" style={{ color: colors.primary.normal, fontWeight: 600 }}>Información</h2>
      </div>
      <div className="d-flex flex-row justify-content-center flex-wrap">
        <div className="d-flex flex-column">
          <div className="d-flex flex-row justify-content-center p-3">
            <FacebookFilled
              className="mx-2 tada"
              onClick={() => window.open('https://facebook.com/aodevmx')}
              style={{ fontSize: '2rem', color: colors.primary.normal }}
            />
            {/* <InstagramFilled
                            className='mx-2 tada'
                            onClick={() => window.open("https://instagram.com/aodev.mx")}
                            style={{ fontSize: "2rem", color: colors.primary.normal }} /> */}
            <a aria-label="Enviar correo" role="button" href="mailto: contacto@aodev.com.mx"><MailFilled className="mx-2 tada" style={{ fontSize: '2rem', color: colors.primary.normal }} /></a>
            <a href="tel:6181807434">
              {' '}
              <PhoneFilled className="mx-2 tada" style={{ fontSize: '2rem', color: colors.primary.normal }} />
            </a>
          </div>
        </div>
        <div className="d-flex flex-column align-items-center ">
          <h5 style={{ color: colors.primary.normal }}>618-180-7434</h5>
          <h5 style={{ color: colors.primary.normal }}>contacto@aodev.com.mx</h5>
        </div>
      </div>
      <div className="d-flex flex-row justify-content-center align-items-center flex-wrap text-white" style={{ minHeight: '40px', borderTopLeftRadius: 5, backgroundColor: colors.primary.normal }}>
        <span>
          {`Copyright ©. ${moment().format('YYYY')} Todos los derechos reservados.`}
        </span>
      </div>
    </div>
  );
}
export default MyFooter;
