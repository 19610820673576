import { Button, Divider } from 'antd';
import React from 'react';
import { VscDebugBreakpointLog } from 'react-icons/vsc';
import colors from '../../utils/colors';
import './EmailCard.css';

function EmailCard({ title, price, description }) {
  return (
    <div
      className="mb-4 mx-3 p-0 email_card"
    >
      <div>
        <div className="d-flex flex-column px-4 pt-4 pb-2">
          <span>{title}</span>
          <h1 style={{ fontWeight: '600', fontSize: '30px' }}>
            {price}
            *
          </h1>
        </div>
        <Divider className="m-0 p-0" />
        <div className="d-flex flex-column p-4">
          {description.map((value) => (
            <div className="d-flex flex-row ">
              <div>
                <VscDebugBreakpointLog color={colors.primary.normal.light} />
              </div>
              <span className="mb-2">{value}</span>
            </div>
          ))}
          <div className="d-flex flex-row justify-content-center mt-3">
            <Button
              type="primary"
              style={
                {
                  backgroundColor: colors.primary.normal, border: '0px', width: '100px', borderRadius: '100px',
                }
}
            >
              <a href="#contacto" style={{ textDecoration: 'none' }}>Solicitar</a>
            </Button>
          </div>

        </div>
      </div>
    </div>
  );
}

export default EmailCard;
