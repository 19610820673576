import React from 'react';
import { createRoot, hydrateRoot } from 'react-dom/client';
import App from './App';
import './index.css';

const domNode = document.getElementById('root');
const reactNode = <App />;

if (domNode.hasChildNodes()) {
  const root = hydrateRoot(domNode, reactNode);
  root.render(reactNode);
} else {
  const root = createRoot(domNode);
  root.render(reactNode);
}
